import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private _errorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );
  public error$ = this._errorSubject.asObservable();

  setError(value: string) {
    if (value) {
      const currentError = this._errorSubject.getValue();
      if (!currentError.split('\n').includes(value)) {
        const updatedError = currentError ? `${currentError}\n${value}` : value;
        this._errorSubject.next(updatedError);
      }
    } else {
      this._errorSubject.next('');
    }
  }
}
